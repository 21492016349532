const config = {
    development: {
        releaseVersion: "3.2.5",
        environment: "development",
        webApi: {
            rootPath: "https://fa-actionarena-api-dev.azurewebsites.net",
            apiVersion: "/api/v1/",
            scopes: ["https://fa-actionarena-api-dev.azurewebsites.net/user_impersonation"]
        },
        storage: {
            "attachementsPath": "https://fa-actionarena-api-dev.azurewebsites.net/api/v1/attachments/"
        },
        monitoring: {
            instrumentationKey: "76173389-e1fc-4ef1-aa90-13bc7280d56c",
            maxBatchInterval: 0,
            disableTelemetry: true
        },
        msalConfig: {
            auth: {
                clientId: "67591bb1-3b8d-4366-b2ce-b5383f5af44a",
                authority: "https://login.microsoftonline.com/18bc60c4-5632-4266-8484-f498b934f0db",
                redirectUri: "https://actionarena-test.bangbonsomer.com/.auth/login/aad/callback"
            },
            cache: {
                cacheLocation: "sessionStorage",    // This configures where your cache will be stored
                storeAuthStateInCookie: false,      // Set this to "true" if you are having issues on IE11 or Edge
            }
        }
    },
    production: {
        releaseVersion: "3.2.5",
        environment: "production",
        webApi: {
            rootPath: "https://fa-actionarena-api-prod.azurewebsites.net",
            apiVersion: "/api/v1/",
            scopes: ["https://fa-actionarena-api-prod.azurewebsites.net/user_impersonation"]
        },
        storage: {
            "attachementsPath": "https://fa-actionarena-api-prod.azurewebsites.net/api/v1/attachments/"
        },
        monitoring: {
            instrumentationKey: "5eeb40ba-0e46-4c3f-97ea-a194b59ab705",
            maxBatchInterval: 0,
            disableTelemetry: true
        },
        msalConfig: {
            auth: {
                clientId: "5f283e23-9a46-4512-8a99-36b27d1ef9c5",
                authority: "https://login.microsoftonline.com/18bc60c4-5632-4266-8484-f498b934f0db",
                redirectUri: "https://actionarena.bangbonsomer.com/.auth/login/aad/callback"
            },
            cache: {
                cacheLocation: "sessionStorage",    // This configures where your cache will be stored
                storeAuthStateInCookie: false,      // Set this to "true" if you are having issues on IE11 or Edge
            }
        }
    }
};

// Choose environment [config.development || config.production ]
let selectedEnvironment = config.production;
// REMEMBER TO UPDATE RELEASE VERSION NUMBER TO ABOVE CONFIGURATION !!
// REMEMBER TO UPDATE VERSION HISTORY PAGE!!

// Set localhost redirect url for the development
if (selectedEnvironment.environment === "development" && window.location.href.indexOf("localhost") !== -1) {
    selectedEnvironment.msalConfig.auth.redirectUri = "http://localhost:3000/.auth/login/aad/callback";
}

// Export selected environment
export default selectedEnvironment;
